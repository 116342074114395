import Vue from "vue";
import Router from "vue-router";
// import { checkLineUser } from '@/config/line-service'

import store from "@/store";

Vue.use(Router);

const rolePermission = {
  "admin": ["Admin"],
  "admin&admin_branch": ["Admin", "Admin Branch"],
}
const selectRolePermission = "admin&admin_branch";

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // checkLineUser: checkLineUser,
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "",
      redirect: "login",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      meta: { roleUser: rolePermission[selectRolePermission] },
      children: [
        {
          name: "login",
          path: "login",
          component: () => import("@/views/Login"),
          meta: { roleUser: [""] },
          beforeEnter: (to, from, next) => {
            window.localStorage.clear();
            next();
          },
        },
      ],
    },

    {
      path: "",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "reward",
          component: () => import("@/views/AdminManager/Reward"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
        {
          name: "rewardreport",
          path: "reward/report/:name/:id",
          component: () => import("@/views/AdminManager/RewardReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
        {
          name: "reward-report-code",
          path: "reward/report/code/:name/:id",
          component: () => import("@/views/AdminManager/RewardCodeReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
        {
          path: "shipment",
          component: () => import("@/views/AdminManager/Shipment"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Shipment" },
        },
      
        {
          path: "dashboard",
          component: () => import("@/views/AdminManager/Dashboard.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Dashboard" },
        },
        {
          path: "ohc-dashboard",
          component: () => import("@/views/AdminManager/OhcDashboard.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-OHC-Dashboard" },
        },
        {
          path: "reportKahoot",
          component: () => import("@/views/AdminManager/ReportKahoot.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-ReportKahoot" },
        },
        {
          path: "kahoot-detail/:id/:name",
          name: "kahoot-detail",
          component: () => import("@/views/AdminManager/KahootDetail.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-ReportKahoot" },
        },
        {
          path: "kahoot",
          beforeEnter: () => {
            window.open("https://create.kahoot.it/creator", "_blank");
          },
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Activity" },
        },
        {
          path: "ee-survey",
          component: () => import("@/views/AdminManager/EESurvey.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-OHC-Dashboard" }
        }
      ],
    },
  
    {
      path: "/setting",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "general-setting",
          component: () => import("@/views/AdminManager/Information.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Setting" },
        },
        {
          path: "custom-page",
          component: () => import("@/views/AdminManager/CustomPage"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Setting" },
        },

        {
          path: "banner",
          component: () => import("@/views/AdminManager/PublicRelations.vue"),
          meta: {
            roleUser: rolePermission[selectRolePermission],
            permission: "Access-Setting",
          },
        },
        {
          path: "branch",
          component: () => import("@/views/AdminManager/PublicRelations.vue"),
          meta: {
            roleUser: rolePermission[selectRolePermission],
            permission: "Access-Setting",
          },
        },
        {
          path: "people",
          component: () => import("@/views/AdminManager/UserAdmin.vue"),
          meta: {
            roleUser: rolePermission[selectRolePermission],
            permission: "Access-Setting",
          },
        },

        {
          path: "account",
          component: () => import("@/views/AdminManager/Account.vue"),
          meta: {
            roleUser: rolePermission[selectRolePermission],
            permission: "Access-Setting",
          },
        },
      ],
    },
    {
      path: "/report",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "otp-usage",
          component: () => import("@/views/AdminManager/OTPUsage.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Report" },
        },
        {
          path: "transaction-usage",
          component: () => import("@/views/AdminManager/TransactionUsage.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Report" },
        },
      ],
    },
    {
      path: "/employee",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "employee",
          component: () => import("@/views/AdminManager/Employee"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Employees" },
        },
        {
          path: "department",
          component: () => import("@/views/AdminManager/Department.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Employees" },
        },
        {
          path: "division",
          component: () => import("@/views/AdminManager/Division.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Employees" },
        },
        {
          path: "branch",
          component: () => import("@/views/AdminManager/Branch.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Employees" },
        },
        {
          name: "branch-employee-list",
          path: "branch/employee-list/:id",
          component: () => import("@/views/AdminManager/BranchEmployeeList.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Employees" },
        },
      ],
    },
    {
      path:"/reward",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "reward",
          component: () => import("@/views/AdminManager/Reward"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
        {
          path: "reward-category",
          component: () => import("@/views/AdminManager/RewardCategory"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
      ],
    },
    {
      path:"/blogs",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "blogs-admin",
          path: "blogs-admin",
          component: () => import("@/views/AdminManager/Blog.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Blog" },
        },
        {
          name: "blogs-employee",
          path: "blogs-employee",
          component: () => import("@/views/AdminManager/BlogEmployee.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-Reward" },
        },
      ],
    },
  
    {
      path: "/corporate",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "mission-point",
          component: () => import("@/views/AdminManager/MissionPoint"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },

        {
          name: "corporate",
          path: "corporate",
          component: () => import("@/views/AdminManager/CorperateGoal"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "corporate-dashboard",
          path: "corporate-dashboard/:id",
          component: () =>
            import("@/views/AdminManager/CorperateGoalDashboard"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        // {
        //   name: "corporate-compare",
        //   path: "corporate-compare/:id",
        //   component: () => import("@/views/AdminManager/CorperateGoalCompare"),
        //   meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        // },

        {
          path: "activity-approve",
          component: () => import("@/views/AdminManager/MissionApprove"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          path: "mission-suggest",
          component: () => import("@/views/AdminManager/MissionSuggest"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "mission-list",
          path: "mission",
          component: () => import("@/views/AdminManager/Mission"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          path: "mission/manage",
          name: "createMission",
          component: () => import("@/components/missions/CreateMission"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missiondetail",
          path: "mission/:id",
          component: () => import("@/views/AdminManager/MissionDetail"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missionreport",
          path: "mission/report/:name/:id",
          component: () => import("@/views/AdminManager/MissionReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "mission-report-anonymous",
          path: "mission/report-anonymous/:name/:id",
          component: () =>
            import("@/views/AdminManager/MissionReportAnonymous"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missionqr",
          path: "mission/qr-activity/:name/:id",
          component: () => import("@/views/AdminManager/MissionCheckInQr"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missionreportcheckin",
          path: "mission/checkin-report/:name/:mission_id/:isCheckInOnly",
          component: () => import("@/views/AdminManager/MissionCheckInReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "mission-report-checkin",
          path: "mission/report-checkin/:id/:name/:mission_id/:isCheckInOnly",
          component: () => import("@/views/AdminManager/MissionCheckInQrReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "mission-checkin-dashboard",
          path: "mission-checkin-dashboard/:id",
          component: () => import("@/views/AdminManager/MissionCheckInDashboard"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missionreportexam",
          path:
            "mission/report-exam/:name/:id/:expectedNum/:expectedType/:totalPoint",
          component: () => import("@/views/AdminManager/MissionExamReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missionreportkahoot",
          path: "mission/report-kahoot/:name/:id",
          component: () => import("@/views/AdminManager/KahootReport"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "mission-dashboard",
          path: "mission/dashboard/:name/:id",
          component: () => import("@/views/AdminManager/MissionDashboard"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          path: "category",
          component: () => import("@/views/AdminManager/Category.vue"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missionreport-survey-summary",
          path: "mission/reports-survey-summary/:id",
          component: () => import("@/views/AdminManager/MissionReportComponents/SurveySummary"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missionreport-multi-upload",
          path: "mission/report-multi-upload/:id",
          component: () => import("@/views/AdminManager/MissionReportComponents/MultiUpload"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
        {
          name: "missionreport-heart-to-heart",
          path: "mission/report-heart-to-heart/:id/:name",
          component: () => import("@/views/AdminManager/MissionReportComponents/HeartToHeart"),
          meta: { roleUser: rolePermission[selectRolePermission], permission: "Access-CorperateGoal" },
        },
      ],
    },

    {
      path: "*",
      name: "error",
      redirect: "login",
      meta: {
        roleUser: [],
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  let role = store.state.defaultSystem.user;
  let roleName = "";
  if (role == null) {
    roleName = "unknown";
  } else {
    roleName = role.role;
  }

  if (!to.meta.roleUser[0]) {
    return next();
  }

  let checkRoleRoutes = to.meta.roleUser
    ? to.meta.roleUser.includes(roleName)
    : next({ name: "error" });

  if (checkRoleRoutes) {
    if (!checkPermission(to)) {
      return next({ name: "error" });
    }
    return next();
  } else {
    return next({ name: "error" });
  }
});

function checkPermission(to) {
  let role = JSON.parse(store.state.defaultSystem.user.permissions);
  let checkRoleRoutes = to.meta.permission;
  let checkPermission = role.some((item) => item == checkRoleRoutes);
  return checkPermission;
}

export default router;
